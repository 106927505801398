import React from 'react';

const ElectricityBill = ({ data }) => {
  const renderTable = (section) => (
    <div className="mb-6">
      <table className="w-full text-sm border-collapse">
        {/* Table content as in the previous example */}
      </table>
    </div>
  );

  return (
    <div className="font-sans max-w-4xl mx-auto p-6 bg-white">
      <h2 className="text-xl font-bold mb-4 text-[#4A0E0E]">Din spesifikasjon</h2>
      
      {data.sections.map((section, index) => (
        <React.Fragment key={index}>
          {renderTable(section)}
        </React.Fragment>
      ))}

      <div className="border-t border-black pt-2">
        {/* Footer content as in the previous example */}
      </div>
    </div>
  );
};

export default ElectricityBill;