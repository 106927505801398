// src/App.js

import React from 'react';
import ElectricityBill from './components/ElectricityBill';
import { invoiceData } from './data/invoiceData';

function App() {
  return (
    <div className="App">
      <ElectricityBill data={invoiceData} />
    </div>
  );
}

export default App;